import React from 'react';
import ContactComponet from '../components/contactComponent';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Header from '../components/headerComponent';
import NewFooterComponent from '../components/newFooterComponent';

const ContactTemplate = props => {
  const {
    data: {
      wpPage: {
        seo,
        contactSectionImage: {
          contactSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: imageContact },
            },
          },
        },
        formSection: {
          eMail,
          formButtonText,
          formSectionSubtitle,
          formSectionTitle,
          location,
          phoneNumber,
          locationAdd,
          subtitleButton,
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <div className="contact-page">
        <Header />
        <ContactComponet
          subtitle={formSectionSubtitle}
          title={formSectionTitle}
          buttonTitle={formButtonText}
          email={eMail}
          callNumber={phoneNumber}
          location={location}
          imageContact={imageContact}
          locationTextAdd={locationAdd}
          buttonSubtitle={subtitleButton}
        />
      </div>
      <NewFooterComponent isProduct={true} />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "contact" }) {
      contactSectionImage {
        contactSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "transparent"
                placeholder: BLURRED
              )
            }
          }
        }
      }
      formSection {
        eMail
        formButtonText
        formSectionSubtitle
        formSectionTitle
        location
        phoneNumber
        locationAdd
        subtitleButton
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default ContactTemplate;
