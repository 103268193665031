import React from 'react';
import FormPage from './formPage';
import LineForm from '../../static/assets/line-form.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
const ContactComponet = props => {
  const {
    email,
    callNumber,
    location,
    imageContact,
    title,
    buttonTitle,
    subtitle,
    locationTextAdd,
    buttonSubtitle,
  } = props;
  return (
    <div className="contact-section">
      <div className="full-container">
        <h1 className="contact-section-title">Talk to an expert today</h1>
        <div className="row">
          <div className="col-12">
            <div className='contact-section-image'>
            <GatsbyImage
              imgStyle={{ width: '100%' }}
              image={imageContact}
              alt='contact'
              className="contact-section_header"
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="contact-section_tab-section">
                  <FormPage
                    title={title}
                    icon={<LineForm />}
                    subtitle={subtitle}
                    callNumber={callNumber}
                    mailText={email}
                    locationText={location}
                    buttonTitle={buttonTitle}
                    locationTextAdd={locationTextAdd}
                    buttonSubtitle={buttonSubtitle}
                  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactComponet;
