import React from 'react';
import IconPhone from '../../static/assets/Icon-phone.svg';
import IconMail from '../../static/assets/Icon-mail.svg';
import IconLocation from '../../static/assets/Icon-location.svg';
import IconButton from '../../static/assets/button-form-icon.svg';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
const SUBMIT_CONTACT = gql`
  mutation contact($data: ContactUsFormInput!) {
    submitContact(data: $data) {
      errors
    }
  }
`;
const FormPage = props => {
  const {
    buttonTitle,
    locationTextAdd,
    callNumber,
    mailText,
    locationText,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const [contact, { loading }] = useMutation(SUBMIT_CONTACT);
  const isLoading = loading;
  const onSubmitHandler = values => {
    const {
      username,
      firstName,
      lastName,
      phone,
      category,
      additionalText,
      company,
      referralText,
    } = values;
    const data = {
      email: username,
      firstName,
      lastName,
      phone,
      category,
      additionalText,
      company,
      referralText,
    };
    contact({ variables: { data } })
      .then(response => {
        console.log(response);
        toast.success('Message sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(errors => {
        console.log(errors);
        toast.error('There was a problem. Please check again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  return (
    <div className="full-container">
      <div className="form-component-container">
        <div className="header-section">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-5 order-2 order-sm-1 contact-section-info-wrapper">
              <div className={'contact-section_rectangle-form'}>
                <div className="inner-container">
                  <div>
                    <p className="contact-section_text-important">
                      Request a demo
                    </p>
                  </div>
                  <a
                    aria-label="callNumber"
                    href={`tel:${callNumber}`}
                    className="contact-section_container-phone"
                  >
                    <div className="contact-section_icon-col ">
                      <IconPhone />
                    </div>
                    <div className="contact-section_text-col ">
                      <p className="contact-section_text ">{callNumber}</p>
                    </div>
                  </a>
                  <a
                    aria-label="mail"
                    href={`mailto:${mailText}`}
                    className="contact-section_container"
                  >
                    <div className="contact-section_icon-col ">
                      <IconMail />
                    </div>
                    <div className="contact-section_text-col ">
                      <p className="contact-section_text">{mailText}</p>
                    </div>
                  </a>
                  <div className="contact-section_container">
                    <div className="contact-section_icon-col ">
                      <IconLocation />
                    </div>
                    <div className="contact-section_text-col ">
                      <p className="contact-section_text">
                        {locationText}
                        <br />
                        {locationTextAdd}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6  col-lg-7 order-1 order-sm-2">
              <form
                className="form-section"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="firstName" className="labelColor">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          disabled={isLoading}
                          className={`form-section_control ${
                            errors.firstName ? 'is-invalid' : ''
                          }`}
                          {...register('firstName', {
                            required: 'Enter your first name!',
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.firstName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="lastName" className="labelColor">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          className={`form-section_control ${
                            errors.lastName ? 'is-invalid' : ''
                          }`}
                          {...register('lastName', {
                            required: 'Enter your last name!',
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.lastName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="email" className="labelColor">
                          Business Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          disabled={isLoading}
                          name="email"
                          className={`form-section_control ${
                            errors.username ? 'is-invalid' : ''
                          }`}
                          {...register('username', {
                            required: 'Enter your business email!',
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.username?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="phone" className="labelColor">
                          Phone
                        </label>
                        <input
                          type="phone"
                          id="phone"
                          className="form-section_control"
                          {...register('phone')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="email" className="labelColor">
                          Company Name
                        </label>
                        <input
                          type="text"
                          id="companyName"
                          disabled={isLoading}
                          name="company"
                          className={`form-section_control ${
                            errors.company ? 'is-invalid' : ''
                          }`}
                          {...register('company', {
                            required: 'Enter your company name!',
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.company?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <label htmlFor="phone" className="labelColor">
                          How did you hear about us?
                        </label>
                        <input
                          type="text"
                          id="about"
                          disabled={isLoading}
                          name="referralText"
                          className={`form-section_control ${
                            errors.referralText ? 'is-invalid' : ''
                          }`}
                          {...register('referralText', {
                            required: 'This field is required!',
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.referralText?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 note-input">
                      <label htmlFor="message" className="labelColor">
                        What else should we know?
                      </label>
                      <input
                        type="text"
                        className="form-section_control"
                        id="message"
                        placeholder="Write your message..."
                        name="additionalText"
                        {...register('additionalText')}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-section_button">
                      <button
                        type="submit"
                        className="form-section_submit"
                        aria-label="submit"
                      >
                        {!loading ? (
                          <>
                            {buttonTitle}
                            <IconButton className="form-section_button-icon" />
                          </>
                        ) : (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormPage;
